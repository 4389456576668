import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Chart from 'chart.js/auto';
import Slider from 'react-slick';
import AddToRadar from './AddToRadar';
import Header from './Header';
import './ProductPage.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { capitalizeFirstLetterOfEachWord, formatPrice } from '../utils';
import ProductBox from './ProductBox';
import Footer from './Footer';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import BackButton from './BackButton';

const ProductPage = () => {
    const { t, i18n } = useTranslation();
    const { id } = useParams();
    const [product, setProduct] = useState(null);
    const [similarProducts, setSimilarProducts] = useState([]);
    const chartRef = useRef(null);
    const chartInstanceRef = useRef(null);

        // Your Amazon affiliate tracking ID
        const affiliateId = 'glamalertz-21'; // Replace with your actual tracking ID

        // Function to generate the affiliate link in Amazon's simplified format
const getAffiliateLink = (link, store) => {
    const url = new URL(link);
    
    // Only apply affiliate tag if it's an Amazon product
    if (store === 'amazon') {
        // Extract the ASIN from the Amazon URL
        const asinMatch = url.pathname.match(/\/dp\/([A-Z0-9]{10})/);
        
        if (asinMatch && asinMatch[1]) {
            const asin = asinMatch[1];
            
            // Create the simplified link
            const simplifiedLink = `https://www.amazon.sa/dp/${asin}/ref=nosim?tag=${affiliateId}`;
            
            return simplifiedLink;
        }
    }
    
    // Return the original link if not Amazon or if ASIN extraction fails
    return link;
};

// // Function to generate the affiliate link
// const getAffiliateLink = (link, store) => {
//     // Log the original link
//     console.log('Original Link:', link);
    
//     const url = new URL(link);
    
//     // Only append the tracking ID if the product is from Amazon
//     if (store === 'amazon') {
//         console.log('Appending affiliate tag...');
//         url.searchParams.append('tag', affiliateId); // Appending the tracking ID
//     } else {
//         console.log('Store is not Amazon, skipping tag append.');
//     }

//     // Log the generated link
//     console.log('Generated Link:', url.toString());
//     return url.toString();
// };



    const fetchProductDetails = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/main/product/${id}`);
            setProduct(response.data);
            // console.log(product, "product") 
        } catch (error) {
            console.error("Error fetching product details:", error);
        }
    };

    const fetchSimilarProducts = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/main/similar/${id}`);
            const fetchedProducts = response.data;
    
            // Use a Set to track unique product IDs
            const uniqueProducts = [];
            const productIds = new Set();
    
            for (const product of fetchedProducts) {
                if (!productIds.has(product._id)) {
                    uniqueProducts.push(product);
                    productIds.add(product._id);
                }
            }
    
            setSimilarProducts(uniqueProducts);
            // console.log(similarProducts, "similar products")
        } catch (error) {
            console.error("Error fetching similar products:", error);
        }
    };

    const fetchPriceHistory = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/product/history/${id}`);
            const priceHistory = response.data.map(item => ({
                ...item,
                price: parseFloat(item.price.match(/\d+(\.\d+)?/)[0])
                // price: item.price
            }));

            // console.log(priceHistory, "price history")

            const labels = priceHistory.map(entry => new Date(entry.timestamp).toLocaleDateString());
            const data = priceHistory.map(entry => entry.price);
    
            if (chartInstanceRef.current) {
                chartInstanceRef.current.destroy();
            }
    
            if (chartRef.current) {
                const ctx = chartRef.current.getContext('2d');
                chartInstanceRef.current = new Chart(ctx, {
                    type: 'line',
                    data: {
                        labels,
                        datasets: [{
                            label: 'Price History',
                            data,
                            borderColor: 'rgba(75, 192, 192, 1)',
                            borderWidth: 2,
                            backgroundColor: 'rgba(75, 192, 192, 0.2)',
                            pointBackgroundColor: 'rgba(75, 192, 192, 1)',
                            pointBorderColor: '#fff',
                            pointHoverBackgroundColor: '#fff',
                            pointHoverBorderColor: 'rgba(75, 192, 192, 1)',
                        }]
                    },
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                        scales: {
                            x: {
                                title: {
                                    display: true,
                                    text: 'Date'
                                }
                            },
                            y: {
                                title: {
                                    display: true,
                                    text: 'Price (SAR)'
                                }
                            }
                        },
                        plugins: {
                            legend: {
                                display: true
                            }
                        }
                    }
                });
            }
        } catch (error) {
            console.error("Error fetching price history:", error);
        }
    };
    

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        fetchProductDetails();
        fetchSimilarProducts();
    }, [id]);

    useEffect(() => {
        if (product) {
            fetchPriceHistory();
            scrollToTop();
        }
    }, [product]);

   


    const slidesToShowLarge = (similarProducts.length < 3)? similarProducts.length : 3;
    const slidesToShowMedium = (similarProducts.length < 2)? similarProducts.length : 2;
    // const slidesToShowSmall = (similarProducts.length < 1)? similarProducts.length : 1;
    const settings = {
        dots: true,
        // infinite: true,
        infinite: false,
        speed: 500,
        // slidesToShow: 3,
        slidesToShow: slidesToShowLarge,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    // slidesToShow: 2,
                    slidesToShow: slidesToShowMedium,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div className="product-page-container">
            {product ? (
                <>
                   <Helmet>
                        <title>{`${capitalizeFirstLetterOfEachWord(product.name)} | GlamAlertz`}</title>
                        <meta name="description" content={`Get the best deals on ${product.name}. Check out the latest prices and availability.`} />
                        <meta property="og:title" content={`${capitalizeFirstLetterOfEachWord(product.name)} | GlamAlertz`} />
                        <meta property="og:description" content={`Get the best deals on ${product.name}. Check out the latest prices and availability.`} />
                        <meta property="og:image" content={product.image || "/logo2.png"} />
                        <meta name="robots" content="index, follow" />
                    </Helmet>
                    <Header />
                    <BackButton />
                    <div className="product-page">
                        <div className="product-header">
                            <div className="product-image">
                                <img 
                                    src={product.image || "/logo2.png"} 
                                    alt={product.name} 
                                    onError={(e) => e.target.src = '/logo2.png'} 
                                />
                                <img 
                                    className="store-logo"
                                    src={product.storeLogo} 
                                    alt={product.store} 
                                    onError={(e) => e.target.src = '/logo2.png'} 
                                />
                            </div>
                            <div className="product-info">
                                <h2>{capitalizeFirstLetterOfEachWord(product.name)}</h2>
                                <div className="price-box">
                                    {product.prices.discounted ? (
                                        <>
                                            <span className="discounted-price">Now: {`${formatPrice(product.prices.discounted)} ${product.currency}`}</span>
                                        </>
                                    ) : (
                                        <span className="original-price-only">Now: {`${formatPrice(product.prices.original)} ${product.currency}`}</span>
                                    )}
                                </div>

                        <div className="product-actions">
                            <a 
                              href={getAffiliateLink(product.link, product.store)} className="get-this-item-now" target="_blank" rel="noopener noreferrer">{t('productPage.buyNow')}</a> 
                            <AddToRadar product={product} />
                        </div>


                                <div className="price-history-graph">
                                    <canvas ref={chartRef}></canvas>
                                </div>
                            </div>
                        </div>
                        {/* <div className="product-description">
                            <h3>Description</h3>
                            <p>{product.description}</p>
                        </div> */}

                        { similarProducts.length ? 
                        <div className="product-availability">
                            <h3>{t('productPage.similarItems')}</h3>
                            <Slider {...settings}>
                                {similarProducts.map((product) => (
                                   
                                    <div key={product._id} className="carousel-item">
                                        <ProductBox product={product} 
                                        // onClick={scrollToTop} 
                                        />
                                    </div>
                                ))}
                            </Slider>
                        </div> : null }
                    </div>
                </>
            ) : ( 
                <p>{t('general.loading')}</p>
            )}
            <Footer />
        </div>
    );
};

export default ProductPage;